:root {
  --dark-gray: #4d5057;
  --dark-green: #4e6e5d;
  --shamrock: #4da167;
  --dark-pastel: #41c745;
  --linen: #f9ebe0;
  --black: var(black);
}

.page-title {
  padding: 5%, 13%;
  margin: 2%;
  text-align: center;
}

.highlighted-text {
  color: var(--dark-pastel);
  font-weight: bold;
}

.btn {
  margin: 5%;
}

.intro {
  padding: 5%, 13%;
  margin: 5%;
}

.create-account-button {
  background-color: var(--dark-pastel);
  margin: 0 auto; /* Center the button horizontally */
  display: block; /* Ensure the button is treated as a block element */
  text-align: center; /* Ensure text inside the button is centered */
  margin-top: 5%;
}

.content-container {
  display: flex;
  align-items: flex-start;
  gap: 10px;
  margin-bottom: 5%;
}

.text-content {
  flex: 1;
  margin-left: 5%;
  margin-right: 5%;
}

.image-container {
  flex: 0 0 400px;
  max-width: 100%;
  margin-right: 5%;
  margin-left: 5%;
}

.homepage-image {
  width: 100%;
  height: auto;
}

@media (max-width: 768px) {
  .content-container {
    flex-direction: column; /* Stack flex items vertically on small screens */
  }

  .image-container,
  .text-content {
    flex: 0 0 auto; /* Allow both the image and text to take full width of the container */
    margin: 5%; /* Adjust margins to better fit mobile screens */
  }

  .image-container {
    order: -1; /* Optionally place the image above the text */
  }
}

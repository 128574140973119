.page-title {
  padding: 5%, 13%;
  margin: 2%;
  text-align: center;
  font-size: x-large;
}

.content-container {
  display: flex;
  align-items: flex-start;
  gap: 10px;
  margin-bottom: 5%;
}

.text-content {
  flex: 1;
  margin-left: 5%;
  margin-right: 5%;
  font-size: medium;
}

:root {
  --dark-gray: #4d5057;
  --dark-green: #4e6e5d;
  --shamrock: #4da167;
  --dark-pastel: #41c745;
  --linen: #f9ebe0;
  --black: #000000;
}

nav {
  background-color: var(--dark-pastel);
  padding: 10px 20px;
}

/* Title Styling */
.navbar-title {
  flex-shrink: 0;
}

.title {
  margin: 0;
  font-size: 24px;
  text-align: left;
}

.home-link {
  color: white;
  text-decoration: none;
}

.home-link:hover {
  color: var(--black);
}

/* Custom Menu Styling */
.custom-menu {
  background-color: var(--dark-pastel);
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-grow: 1;
  flex-wrap: wrap; /* Allow menu items to wrap to the next line */
}

.custom-menu .ant-menu-item {
  padding: 0 8px !important; /* Adjust padding inside Ant Design's Menu.Item */
}

/* Menu Link Color and Hover Styling */
.custom-menu .menu-item-custom a {
  color: white;
  transition: color 0.3s;
}

.custom-menu .menu-item-custom a:hover {
  color: black;
}

.custom-menu .menu-item-custom a:active,
.custom-menu .menu-item-custom a:focus {
  color: white;
}

/* Responsive Design for Small Screens */
@media (max-width: 768px) {
  .navbar-title {
    text-align: center;
    width: 100%;
    margin-bottom: 10px;
  }

  .navbar-menu {
    text-align: left;
    width: 100%;
  }

  .custom-menu {
    justify-content: center; /* Center the wrapped items */
    flex-wrap: wrap; /* Ensure wrapping on smaller screens */
    gap: 0px; /* Add some spacing between wrapped items */
  }

  .menu-item-custom {
    margin: 0; /* Space between menu items when wrapped */
    font-size: smaller;
  }

  .custom-language-switch {
    transform: scale(0.75); /* Reduce the switch size to 80% */
    margin: 0; /* Add margin if needed */
  }
}

.app-footer {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f1f1f1;
  padding: 5px 0;
  width: 100%;
  position: fixed;
  bottom: 0;
}

.container {
  font-size: x-small;
  text-align: center;
  margin: 0 auto;
}

.account-link {
  background-color: var(--dark-pastel);
  display: block;
  margin: 0 auto;
  width: fit-content;
}

.login-form {
  width: 50%; /* Sets the width of the form to 50% of its parent container */
  margin: 0 auto; /* Centers the form horizontally */
}

@media (max-width: 768px) {
  .login-form {
    width: 75%; /* Increase width for smaller screens */
  }
}

@media (max-width: 480px) {
  .login-form {
    width: 90%; /* Further increase width for very small screens */
  }
}

.login-form-item {
  text-align: center;
}

.p-tag {
  text-align: center;
  width: 50%;
  margin: 0 auto;
  margin-bottom: 10px;
}
